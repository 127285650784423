import React, { useEffect } from "react";
import SideBar from "../Components/SideBar";
import UploadNotice from "../Components/Notice/UploadNotice";
import RecentNotice from "../Components/Notice/RecentNotice";
import TopBar from "../Components/TopBar";
import { useNavigate } from "react-router-dom";

function Notice() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	return (
		<>
			<SideBar />
			<TopBar />
			<div className="container">
				<UploadNotice />
				<RecentNotice />
			</div>
		</>
	);
}

export default Notice;
