import React from "react";
import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { GrResources } from "react-icons/gr";
import {
	AiOutlineHome,
	AiOutlineFilePdf,
	AiOutlineBarChart,
} from "react-icons/ai";
import { PiStudentDuotone } from "react-icons/pi";
import { RiPresentationLine } from "react-icons/ri";
import { BsPencilSquare, BsCurrencyRupee } from "react-icons/bs";
import { BiChat } from "react-icons/bi";
import { FiLogOut, FiAlertTriangle } from "react-icons/fi";
import { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import Logout from "./Logout";

function SideBar() {
	const [collapsed, setCollapsed] = useState(false);
	useEffect(() => {
		if (window.innerWidth < 700) {
			setCollapsed(true);
		}
	}, []);

	const [openModal, setOpenModal] = useState(false);
	const openLogoutModal = () => {
		setOpenModal(true);
	};
	const closeLogoutModal = () => {
		setOpenModal(false);
	};

	return (
		<>
			<Sidebar id="side-bar" collapsed={collapsed}>
				<div className="logo-desc">
					<img src="/Images/logo.png" alt="logo" />
					<h4>University Department of Computer Application</h4>
				</div>
				<Menu
					menuItemStyles={{
						button: ({ level, active, disabled }) => {
							// only apply styles on first level elements of the tree
							if (level === 0)
								return {
									color: disabled ? "#f5d9ff" : "#464255",
									backgroundColor: active ? "#b3c7ec" : undefined,
								};
						},
					}}
				>
					<MenuItem
						active={window.location.pathname === "/"}
						component={<Link to="/" />}
					>
						<div className="menu-item">
							<span>
								<AiOutlineHome className="menu-icon" />
							</span>
							<span>Dashboard</span>
						</div>
					</MenuItem>
					<MenuItem
						active={window.location.pathname === "/fee"}
						component={<Link to="/fee" />}
					>
						<div className="menu-item">
							<span>
								<BsCurrencyRupee className="menu-icon" />
							</span>
							<span>Fee Collection</span>
						</div>
					</MenuItem>

					<MenuItem
						active={window.location.pathname === "/notice"}
						component={<Link to="/notice" />}
					>
						<div className="menu-item">
							<span>
								<AiOutlineFilePdf className="menu-icon" />
							</span>
							<span>Notice</span>
						</div>
					</MenuItem>
					<MenuItem
						active={
							window.location.pathname === "/studentList" ||
							window.location.pathname === "/studentdetail"
						}
						component={<Link to="/studentList" />}
					>
						<div className="menu-item">
							<span>
								<PiStudentDuotone className="menu-icon" />
							</span>
							<span>Students</span>
						</div>
					</MenuItem>
					<MenuItem
						active={window.location.pathname === "/alumni"}
						component={<Link to="/alumni" />}
					>
						<div className="menu-item">
							<span>
								<AiOutlineBarChart className="menu-icon" />
							</span>
							<span>Alumni</span>
						</div>
					</MenuItem>
					{/* <MenuItem
						active={window.location.pathname === "/student-result"}
						component={<Link to="/student-result" />}
						disabled
					>
						<div className="menu-item">
							<span>
								<BsPencilSquare className="menu-icon" />
							</span>
							<span>Exam & Result</span>
						</div>
					</MenuItem> */}
					<MenuItem
						active={window.location.pathname === "/research"}
						component={<Link to="/research" />}
						
					>
						<div className="menu-item">
							<span>
								<RiPresentationLine className="menu-icon" />
							</span>
							<span>Research</span>
						</div>
					</MenuItem>
					<MenuItem
						active={window.location.pathname === "/enquiry"}
						component={<Link to="/enquiry" />}
					>
						<div className="menu-item">
							<span>
								<BiChat className="menu-icon" />
							</span>
							<span>Enquiry</span>
						</div>
					</MenuItem>
					<MenuItem
						active={window.location.pathname === "/resources"}
						component={<Link to="/resources" />}
					>
						<div className="menu-item">
							<span>
								<GrResources className="menu-icon" color="#FFF"/>
							</span>
							<span>Resources</span>
						</div>
					</MenuItem>
					<MenuItem
						active={window.location.pathname === "/complaint"}
						component={<Link to="/complaint" />}
					>
						<div className="menu-item">
							<span>
								<FiAlertTriangle className="menu-icon" />
							</span>
							<span>Complaint</span>
						</div>
					</MenuItem>

					<MenuItem onClick={openLogoutModal}>
						<div className="menu-item">
							<span>
								<FiLogOut className="menu-icon" />
							</span>
							<span>Logout</span>
						</div>
					</MenuItem>
					<Modal open={openModal} onClose={closeLogoutModal} center>
						<Logout />
					</Modal>
				</Menu>
			</Sidebar>
		</>
	);
}

export default SideBar;
