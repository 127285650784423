import { Link } from "react-router-dom";
import { BsArrowRight, BsFillArchiveFill } from "react-icons/bs";
import { RxDividerVertical } from "react-icons/rx";

function Complain(props) {
  const data = props.data;

  return (
    <>
      <div className="Recent-notice-search">
        <div className="Heading">
          <h2>
            Compl<span className="underline">ain Box</span>
          </h2>
        </div>
      </div>
      <div className="notice-section">
        <ul>
          {data === undefined ||
            data?.map((item) => {
              return (
                <li>
                  <div className="item">
                    <tr className="notice">
                      <td className="notes-icon">
                        <BsFillArchiveFill className="icon" />
                      </td>
                      <td>
                        <span>{item.title}</span>
                        <br />
                        <span style={{ display: "flex", gap: "3px" }}>
                          <p>{item.student.name}</p> <RxDividerVertical />
                          <p>{new Date(item.created_at).toDateString()}</p>
                        </span>
                      </td>
                    </tr>
                  </div>
                </li>
              );
            })}
        </ul>
        <Link to="/complaint">
          <button>
            View More <BsArrowRight />
          </button>
        </Link>
      </div>
    </>
  );
}

export default Complain;
