import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../Loader";
import axios from "axios";

function ListResources() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchListResources();
  }, []);

  function fetchListResources() {
    setLoading(true);
    axios
      .get("/api/resource/get", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function deleteResources(r_id) {
    setLoading(true);
    axios
      .post(
        "/api/resource/delete",
        {
          id: r_id,
        },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        fetchListResources();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="AlumniListTable">
        <table>
          <thead>
            <tr className="Table-Header">
              <th>S.No</th>
              <th>Date</th>
              <th>Title</th>
              <th>Description</th>
              <th>Email</th>
              <th>Designation</th>
              <th>Phone.</th>
              <th style={{ width: "100px" }}>Link</th>
              <th>Action</th>
              {/* <th>Amount</th> */}
            </tr>
          </thead>
          <tbody>
            {data === undefined ||
              data?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    // onClick={() => {
                    // 	navigate("/studentdetail/" + item.id);
                    // }}
                  >
                    <td>{index + 1}</td>
                    <td>{new Date(item.created_at).toLocaleDateString()}</td>
                    <td>{item.title}</td>
                    <td className="desc">
                      {item.desc === null ? "-" : item.desc}{" "}
                    </td>
                    <td>{item.email === null ? "-" : item.email}</td>
                    <td>
                      {item.designation === null ? "-" : item.designation}
                    </td>
                    <td>{item.phone === null ? "-" : item.phone}</td>
                    <td>
                      {item.link === null ? "-" : <a href={item.link}>Url</a>}
                    </td>
                    <td className="res-status">
                      {/* <button
												className="edit-btn"
												// onClick={() => {
												// 	editStudent(item.id);
												// }}
											>
												Edit
											</button> */}
                      {item.title.search("FEE") == "-1" ? (
                        <button
                          className="delete-btn"
                          onClick={() => {
                            deleteResources(item.id);
                          }}
                        >
                          Delete
                        </button>
                      ) : (
                        " "
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ListResources;
