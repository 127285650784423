import React, { useEffect, useState } from "react";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import axios from "axios";
import Loader from "../Components/Loader";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

function TxnHistory() {
  const navigate = useNavigate();
  const [txnData, setTxnData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchByName, setSearchByName] = useState(false);
  const [openPendingData, setOpenPendingData] = useState(false);
  const [loading, setLoding] = useState(false);
  useEffect(() => {
    FetchTxn();
    checkLogin();
  }, []);
  const checkLogin = () => {
    if (localStorage.getItem("type") !== "admin") {
      navigate("/login");
    } else if (!localStorage.getItem("auth_token")) {
      navigate("/login");
    }
  };
  function FetchTxn() {
    setLoding(true);
    axios
      .get("/api/student/listFeeRequestListPending", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoding(false);
        setOpenPendingData(true);
        if (res.data.msg !== "No data Found") {
          setTxnData(res.data.data);
        } else {
          setTxnData([]);
        }
      })
      .catch((err) => {
        setLoding(false);
        console.log(err);
      });
  }

  function updateTxn(e, tid) {
    setLoding(true);
    axios
      .post(
        "/api/student/updateFeeStatus",
        {
          id: tid,
          status: e.target.value,
        },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoding(false);
        alert(res.data.msg);
        FetchTxn();
      })
      .catch((err) => {
        setLoding(false);
        console.log(err);
      });
  }

  function seeAllTxn() {
    setLoding(true);
    axios
      .get("/api/student/listFeeRequestListAll", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoding(false);
        if (res.data.msg !== "No data Found") {
          setTxnData(res.data.data);
          setOpenPendingData(false);
        }
      })
      .catch((err) => {
        setLoding(false);
        console.log(err);
      });
  }
  /*Pagination*/
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);
  const itemsPerPage = 15;
  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(txnData.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(txnData.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, txnData]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % txnData.length;

    setItemOffSet(newOffset);
  };
  return (
    <>
      {loading ? <Loader /> : ""}
      <SideBar />
      <TopBar />
      <div className="container">
        {/* Alumni Header  */}
        <div className="Recent-notice-search">
          <div className="Heading">
            <h2>
              <span className="underline"> Transact</span>ion History
            </h2>
          </div>

          <div className="search-filter">
            <input
              type="text"
              name="student"
              placeholder="Search By Name"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchByName(true);
              }}
            />
            <select
              name="course"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchByName(false);
              }}
            >
              <option value="">Course</option>
              <option value="BCA">BCA</option>
              <option value="MCA">MCA</option>
            </select>
            {/* <select name="sem">
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
						</select> */}
          </div>
        </div>

        <div className="AlumniListTable">
          <table>
            <thead>
              <tr className="Table-Header">
                <th>S.No</th>
                <th>Date</th>
                <th>Name</th>
                <th>Roll No.</th>
                {/* <th>Session</th> */}
                <th>Course</th>
                <th>Sem</th>
                <th>UTR No.</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems === undefined ||
                currentItems
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : searchByName
                      ? item.student.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      : item.student.course === null
                      ? " "
                      : item.student.course
                          .toLowerCase()
                          .includes(search.toLowerCase());
                  })
                  ?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{new Date(item.created_at).toDateString()}</td>
                        <td>
                          <Link to={`/studentdetail/` + item.student.id}>
                            {item.student.name}
                          </Link>
                        </td>
                        <td>{item.student.classRoll}</td>
                        {/* <td>{item.student.session}</td> */}
                        <td>
                          <Link to={`/studentdetail/` + item.student.id}>
                            {item.student.course}
                          </Link>
                        </td>
                        <td>{item.sem}</td>
                        <td>{item.txnId}</td>
                        <td className="res-status">{item.amount}</td>
                        <td>{item.status}</td>
                        <td>
                          {item.status === "Pending" ? (
                            <button
                              value="Approved"
                              onClick={(e) => {
                                updateTxn(e, item.id);
                              }}
                              className="delete-btn"
                            >
                              Approve
                            </button>
                          ) : (
                            <button
                              value="Pending"
                              onClick={(e) => {
                                updateTxn(e, item.id);
                              }}
                              className="un-publish-btn"
                            >
                              Disapprove
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        {openPendingData === true ? (
          <p className="see-all" onClick={seeAllTxn}>
            See All
          </p>
        ) : (
          <p className="see-all" onClick={FetchTxn}>
            See Pending
          </p>
        )}
        {/* Pagination  */}

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default TxnHistory;
