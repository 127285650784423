import React, { useEffect } from "react";
import TopBar from "../Components/TopBar";
import SideBar from "../Components/SideBar";
import { useNavigate } from "react-router-dom";
import AddResources from "../Components/Resources/AddResources";
import ListResources from "../Components/Resources/ListResources";

function Resources() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	return (
		<>
			<SideBar />
			<TopBar />
			<div className="container">
				<AddResources/>
				<ListResources/>
			</div>
		</>
	);
}

export default Resources;
