import React from 'react'
import {VscBellDot} from "react-icons/vsc"
function TopBar() {
   
  return (
    <header >
        <div className="header-left" >
            <input type="search" name='serach' placeholder='Search Here !'/>
        </div>
        <div className="header-right">
            <span><VscBellDot className='bell-icon'/></span>
            <div className='profile'>
                <span><img src='/Images/logo.png' alt='img'/></span>
                <span>
                    <h4>MCA Dept</h4>
                    <p>Admin Panel</p>
                </span>
            </div>
        </div>
    </header>
  )
}

export default TopBar