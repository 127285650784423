import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
import db from "../../../db.json";

function FeeSubmission() {
	const [loading, setLoading] = useState(false);
	const [course, setCourse] = useState("BCA");

	const dbcourseSem = db.courseSem;
	const dbsession = db.session;

	useEffect(() => {}, []);
	const submitHandler = (e) => {
		e.preventDefault();
		setLoading(true);

		axios
			.post(
				"/api/student/addFeeRequestStudent",
				{
					amount: e.target.amount.value,
					sem: e.target.sem.value,
					txnId: e.target.utr.value,
				},
				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoading(false);
				alert(res.data.msg);
				if (res.data.msg === "Request Sent Successfully") {
					window.location.reload();
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="notice-container fee-details-container">
				<div className="notice-form feeform">
					<form
						method="post"
						enctype="multipart/form-data"
						onSubmit={submitHandler}
					>
						<h3>
							Fee Submis<span className="underline">sion Form</span>
						</h3>
						<div className="form">
							<div className="form-left">
								<div className="input-box">
									<input type="text" name="name" placeholder="Name" required />
								</div>
								<div className="course-sem">
									<div className="input-box">
										<select
											name="course"
											onChange={(e) => {
												setCourse(e.target.value);
											}}
										>
											<option value="BCA">BCA</option>
											<option value="MCA">MCA</option>
										</select>
									</div>
									<div className="input-box">
										<select name="sem">
											{course === "BCA"
												? dbcourseSem[0].BCA.map((item) => {
														return <option value={item}>{item}</option>;
												  })
												: dbcourseSem[0].MCA.map((item) => {
														return <option value={item}>{item}</option>;
												  })}
										</select>
									</div>
								</div>
								<div className="input-box">
									<input
										type="text"
										name="utr"
										placeholder="UTR Number"
										required
									/>
								</div>
							</div>
							<div className="form-right">
								<div className="input-box">
									<input
										type="text"
										name="roll"
										pattern="\d*"
										maxLength={5}
										placeholder="Class Roll No."
										required
									/>
								</div>
								<div className="input-box">
									<select name="session">
										<option value="">Session</option>
										{course === "BCA"
											? dbsession[0].BCA.map((item) => {
													return <option value={item}>{item}</option>;
											  })
											: dbsession[0].MCA.map((item) => {
													return <option value={item}>{item}</option>;
											  })}
									</select>
								</div>
								<div className="input-box">
									<input
										type="number"
										name="amount"
										placeholder="Amount"
										required
									/>
								</div>
							</div>
						</div>
						<button className="submit-btn">Submit</button>
					</form>
				</div>

				<div className="notice-container fee-details">
					<div className=" notice-form">
						<div className="details .notice-form">
							<h4>Account Details</h4><br/>
							<p>Punjab National Bank</p><p>Vbu Branch Hazaribagh</p>
							<ul>
								<li>Account No - <b>0877050000949</b></li>
								<li>IFSC Code - <b>PUNB0174020</b>	</li>
								<li>A/C Holder - Director MCA </li>
							</ul>
						</div>
					</div>
					{/* <img src="/Images/feeform-img.png" alt="img" width={"330px"} /> */}
				</div>
			</div>
		</>
	);
}

export default FeeSubmission;
