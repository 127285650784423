import { Routes, Route, React, BrowserRouter } from "react-router-dom";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

//Import Pages
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Notice from "./Pages/Notice";
import Alumni from "./Pages/Alumni";
import StudentResult from "./Pages/StudentResult";
import Fee from "./Pages/Fee";
import Students from "./Pages/Students";
import Enquiry from "./Pages/Enquiry";
import Research from "./Pages/Research";
import Complaint from "./Pages/Complaint";
import StudentData from "./Pages/StudentData";

import StuHome from "./StudentPanel/Pages/Home";
import StuComplain from "./StudentPanel/Pages/Complain";
import StuFee from "./StudentPanel/Pages/Fee";
import StuDetails from "./StudentPanel/Pages/StudentDetails";
import StuResult from "./StudentPanel/Pages/ResultStudent";
import TxnHistory from "./Pages/TxnHistory";
import Resources from "./Pages/Resources";

function App() {
	useEffect(() => {
		Aos.init({ duration: 1500 });
	}, []);
	return (
		<>
			<BrowserRouter>
				{/* <SideBar/> */}

				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/login" element={<Login />} />
					<Route path="/notice" element={<Notice />} />
					<Route path="/alumni" element={<Alumni />} />
					<Route path="/fee" element={<Fee />} />
					<Route path="/fee/txnHistory" element={<TxnHistory />} />
					<Route path="/studentList" element={<Students />} />
					<Route path="/studentdetail/:stuId" element={<StudentData />} />
					<Route path="/student-result" element={<StudentResult />} />
					<Route path="/enquiry" element={<Enquiry />} />
					<Route path="/research" element={<Research />} />
					<Route path="/resources" element={<Resources />} />
					<Route path="/complaint" element={<Complaint />} />

					<Route path="/student/home" element={<StuHome />} />
					<Route path="/student/complain" element={<StuComplain />} />
					<Route path="/student/Fee" element={<StuFee/>} />
					<Route path="/student/details" element={<StuDetails/>} />
					<Route path="/student/result" element={<StuResult/>} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;
