import React from "react";
import { NavLink } from "react-router-dom";

function NavBar() {
	return (
		<>
			<nav id="stu-nav">
				<ul>
					<NavLink to="/student/complain">
						<li>Complain </li>
					</NavLink>
					<NavLink to="/student/Fee">
						<li>Fee Submission </li>
					</NavLink>
					<NavLink to="/student/details">
						<li>Student Details </li>
					</NavLink>
					{/* <NavLink to = "/student/result"><li>Results</li></NavLink> */}
				</ul>
			</nav>
		</>
	);
}

export default NavBar;
