import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import ComplainList from "../Components/ComplainList";
import TopBar from "../Components/TopBar";
import SideBar from "../Components/SideBar";


function Complaint() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	return (
		<>
			<SideBar />
			<TopBar />
			<ComplainList />
		</>
	);
}

export default Complaint;
