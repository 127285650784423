import React from "react";
// import { useEffect ,useState} from "react";
import { useNavigate } from "react-router-dom";
// import { useCookies } from "react-cookie";

function Logout() {
	// const [cookies, removeCookie] = useCookies();
	

	const navigate = useNavigate();
	// const checkLogin = () => {
	
	// 	if (cookies.auth_token === "undefined") {
	// 		navigate("/login");
	// 	}
	// };
	// useEffect(() => {
	// 	checkLogin();
	// }, []);

	return (
		<>
			<div className="logout">
				<h2>Are You Sure Want to Logout ?</h2>
				<div className="buttons">
					<button
						className="submit-btn"
						onClick={() => {
							localStorage.clear()
							// removeCookie("auth_token")
							// removeCookie("admin")
							// removeCookie("Password")
							navigate("/login")
							// checkLogin();
						}}
					>
						Logout
					</button>
				</div>
			</div>
		</>
	);
}

export default Logout;
