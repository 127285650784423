import React, { useEffect, useState } from "react";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import axios from "axios";
import Loader from "../Components/Loader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Alumni() {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    const checkLogin = () => {
      if (localStorage.getItem("type") !== "admin") {
        navigate("/login");
      } else if (!localStorage.getItem("auth_token")) {
        navigate("/login");
      }
    };
    checkLogin();
  });
  const [alumniList, setAlumniList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAlumni();
  }, []);

  const fetchAlumni = () => {
    setLoading(true);
    axios
      .get("/api/alumini/getAll", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.msg !== "No data found") {
          setAlumniList(res.data.Data);
        } else {
          setAlumniList([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const approveAlumni = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/alumini/approve",
        { id: id },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        alert(res.data.msg);
        fetchAlumni();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const disapproveAlumni = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/alumini/disapprove",
        { id: id },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        alert(res.data.msg);
        fetchAlumni();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteAlumni = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/alumini/delete",
        { id: id },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        fetchAlumni();
        alert(res.data.msg);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  /*Pagination*/
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(alumniList.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(alumniList.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, alumniList]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % alumniList.length;
    setItemOffSet(newOffset);
  };

  return (
    <>
      {loading ? <Loader /> : ""}

      <SideBar />
      <TopBar />
      <div className="container">
        {/* Alumni Header  */}
        <div className="Recent-notice-search">
          <div className="Heading">
            <h2>
              <span className="underline">Alumni </span>Lists
            </h2>
          </div>

          <div className="search-filter">
            {/* <select name="alumni">
							<option>Search Alumni</option>
						</select> */}
            <select
              name="course"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            >
              <option value="">All Here</option>
              <option value="BCA">BCA</option>
              <option value="MCA">MCA</option>
            </select>
            {/* <select name="year">
							<option>2023</option>
							<option>2022</option>
						</select> */}
          </div>
        </div>

        {/* Alumni List  */}
        <div className="AlumniListTable">
          <table>
            <thead>
              <tr className="Table-Header">
                <th>S.No</th>
                <th>Profile</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th>D.O.B</th>
                <th>Roll_No.</th>
                <th>Reg_No.</th>
                <th>Company</th>
                <th>Designation</th>
                <th>Course</th>
                <th>Passing_Year</th>
                <th>LinkedIn</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems === undefined ||
                currentItems
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : item.course === null
                      ? " "
                      : item.course
                          .toLowerCase()
                          .includes(search.toLowerCase());
                  })
                  ?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td key={index + 1}>{index + 1}</td>
                          <td>
                            <img
                              src={
                                "https://api.vbumca.in/storage/app/public/" +
                                item.photo_location
                              }
                              alt="profile"
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.mobile}</td>
                          <td>{new Date(item.dob).toLocaleDateString()}</td>
                          <td>{item.rollno}</td>
                          <td>{item.regno}</td>
                          <td>{item.company}</td>
                          <td>{item.designation}</td>
                          <td>{item.course}</td>
                          <td>{item.passing_year}</td>
                          <td>
                            <Link to={item.linkedin} target="_blank">
                              Link
                            </Link>
                          </td>
                          <td className="action">
                            <button
                              className="delete-btn"
                              onClick={() => {
                                deleteAlumni(item.id);
                              }}
                            >
                              Delete
                            </button>

                            {item.approved == 0 ? (
                              <button
                                className="un-publish-btn"
                                onClick={() => {
                                  approveAlumni(item.id);
                                }}
                              >
                                Approve
                              </button>
                            ) : (
                              <button
                                className="un-publish-btn"
                                onClick={() => {
                                  disapproveAlumni(item.id);
                                }}
                              >
                                Disapprove
                              </button>
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
            </tbody>
          </table>
        </div>

        {/* Pagination  */}

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default Alumni;
