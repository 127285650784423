import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import db from "../../db.json";

function FeeList() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [course, setCourse] = useState("BCA");

  const [searchByName, setSearchByName] = useState(false);
  const [fetchList, setFetchList] = useState([]);
  const dbcourseSem = db.courseSem;

  const navigate = useNavigate();

  useEffect(() => {
    fetchFeeList();
  }, []);
  function fetchFeeList() {
    setLoading(true);
    axios
      .get("/api/student/FeeDueList", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setFetchList(res.data.studentF);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  //  export data
  const newData = fetchList?.map((item, index) => {
    const fee = item?.fee
      ?.map((item) => `${item?.sem} (Amount: ${item.amount})`)
      .join(" & ");
    return {
      S_No: index + 1,
      Name: item?.name,
      Phone: item?.phone,
      Class_Roll: item?.classRoll,
      Course: item?.course,
      Semester: item?.sem,
      Session: item?.session,
      Fee_Due: item?.feeDue,
      Fee_Paid: item?.feePaid,
      Total: fee,
    };
  });
  /*Pagination*/
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(fetchList.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(fetchList.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, fetchList]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % fetchList.length;

    setItemOffSet(newOffset);
  };

  return (
    <>
      {loading ? <Loader /> : ""}

      <div className="container">
        <div className="Recent-notice-search">
          <div className="Heading">
            <h2>
              <span className="underline"> Fees List </span>
            </h2>
          </div>

          <div className="search-filter">
            <input
              type="text"
              name="student"
              placeholder="Search By Name"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchByName(true);
              }}
            />

            <select
              name="course"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchByName(false);
                setCourse(e.target.value);
              }}
            >
              <option value="BCA">BCA</option>
              <option value="MCA">MCA</option>
            </select>
            <select name="sem">
              {course === "BCA"
                ? dbcourseSem[0].BCA.map((item) => {
                    return <option value={item}>{item}</option>;
                  })
                : dbcourseSem[0].MCA.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
            </select>
            {/* <select name="sem">
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
						</select> */}

            <button
              className="fee-due submit-btn"
              onClick={() => {
                navigate("/fee/txnHistory");
              }}
            >
              Pending Txn
            </button>
            <CSVLink data={newData} filename={"FeeList.csv"}>
              <button className=" submit-btn">Export</button>
            </CSVLink>
          </div>
        </div>
        {/* Alumni List  */}
        <div className="AlumniListTable">
          <table>
            <thead>
              <tr className="Table-Header">
                <th>S.No</th>
                <th>Name</th>
                <th>Roll No.</th>
                <th>Contact No.</th>
                <th>Course</th>
                <th>Fee Paid</th>
                <th>Fee Due</th>
                <th>Amount Due</th>
              </tr>
            </thead>

            <tbody>
              {currentItems === undefined ||
                currentItems
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : searchByName
                      ? item.name.toLowerCase().includes(search.toLowerCase())
                      : item.course === null
                      ? " "
                      : item.course
                          .toLowerCase()
                          .includes(search.toLowerCase());
                  })
                  ?.map((item, index) => {
                    return (
                      <tr
                        className="row-link"
                        key={index}
                        onClick={() => {
                          navigate("/studentdetail/" + item.id);
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.classRoll}</td>
                        <td>{item.phone}</td>
                        <td>{item.course}</td>
                        <td>{item.feePaid} Sem</td>
                        <td>{item.feeDue} Sem</td>
                        <td>{item.amount}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>

        {/* Pagination  */}

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default FeeList;
