import React from "react";
import { Link } from "react-router-dom";

function Stats(props) {
	return (
		<>
			<section className="stats-container">
				<Link to="/studentList">
					<div className="stats-card card1">
						<h4>{props.student}</h4>
						<p>Total Student</p>
					</div>
				</Link>
				<Link to="/fee">
					<div className="stats-card card2">
						<h4>{props.fee}</h4>
						<p>Fee Request</p>
					</div>
				</Link>
				<Link to="/alumni">
					<div className="stats-card card3">
						<h4>{props.alumni}</h4>
						<p>Alumni Request</p>
					</div>
				</Link>
			</section>
		</>
	);
}

export default Stats;
