import React from "react";
import { useState } from "react";
import Loader from "../Loader";
import axios from "axios";

function UpdateSem() {
	const [loading, setLoading] = useState(false);
	const updateSemHandler = (e) => {
		e.preventDefault();
		setLoading(true);

		axios
			.post(
				"/api/student/updatesem",
				{
					course: e.target.course.value,
					session: e.target.session.value,
					fromSem: e.target.fromSem.value,
					toSem: e.target.toSem.value,
				},
				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoading(false);
				alert(res.data.msg);
				if(res.data.msg === "Updated Successfully"){
					window.location.reload();
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="update-sem">
				<form onSubmit={updateSemHandler} method="POST">
					<div className="from">
						<label>From</label>
						<div style={{ display: "flex", gap: "20px" }}>
							<div className="input-box">
								<select name="course" required>
									<option value="">Course</option>
									<option value="BCA">BCA</option>
									<option value="MCA">MCA</option>
								</select>
							</div>
							<div className="input-box">
								<select name="fromSem" required>
									<option value="">Sem</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
								</select>
							</div>
							<div className="input-box">
								<select name="session" required>
									<option value="">Session</option>
									<option value="2021-24">2021-24</option>
									<option value="2022-25">2022-25</option>
									<option value="2024-26">2024-26</option>
								</select>
							</div>
						</div>
					</div>
					<div className="to">
						<label>To</label>
						<div className="input-box">
							<select name="toSem" required>
								<option value="">Sem</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
							</select>
						</div>
					</div>
					<div style={{ textAlign: "center" }}>
						<br />
						<input type="submit" value="SUBMIT" className="submit-btn" />
					</div>
				</form>
			</div>
		</>
	);
}

export default UpdateSem;
