import React, { useEffect, useState } from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import { Modal } from "react-responsive-modal";
import Logout from "../../Components/Logout";
import axios from "axios";

function Header(props) {
	const [openModal, setOpenModal] = useState(false);
	const [username, setUsername] = useState("")
	const openLogoutModal = () => {
		setOpenModal(true);
	};
	const closeLogoutModal = () => {
		setOpenModal(false);
	};

  useEffect(()=>{
	fetchStudents()
  },[])
	function fetchStudents() {
		axios
			.get("/api/student/studentDataByToken", {
				headers: {
					"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
				},
			})
			.then((res) => {
				setUsername(res.data.data.name);
			})
			.catch((err) => {
				console.log(err);
			});
	}
	return (
		<header
			style={{ marginLeft: props.margin }}
			className="mobile-student-header"
		>
			<div
				className="header-left"
				style={{
					display: "flex",
					width: "50%",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<img src="/Images/logo.png" alt="img" width={"15%"} />
				<h2>University Department of Computer Application</h2>
			</div>
			<div className="header-right">
				<span onClick={openLogoutModal}>
					<RiLogoutCircleLine className="bell-icon" color="#FF0000" />
				</span>
				<div className="profile">
					<span>
						<h4>MCA Dept</h4>
						<p>{username}</p>
					</span>
				</div>
			</div>
			<Modal open={openModal} onClose={closeLogoutModal} center>
				<Logout />
			</Modal>
		</header>
	);
}

export default Header;
