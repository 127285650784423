import React, { useEffect } from "react";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import StudentList from "../Components/Student/StudentList";
import { useNavigate } from "react-router-dom";

function Students() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	return (
		<>
			<SideBar />
			<TopBar />
			<StudentList/>
		</>
	);
}

export default Students;
