import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import Loader from "../../../Components/Loader";
import db from "../../../db.json";

function StudentDetailsForm() {
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setfile] = useState();
  const [img, setImg] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [rollno, setRollno] = useState("");
  const [regno, setRegno] = useState("");
  const [course, setCourse] = useState("");
  const [sem, setSem] = useState("");
  const [session, setSession] = useState("");
  const [category, setCategory] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [photo, setPhoto] = useState("");

  // db
  // const [selectedCourse, setSelectedCourse] = useState("");
  const dbcourseSem = db.courseSem;
  const dbsession = db.session;

  function imageHandler(e) {
    setfile(URL.createObjectURL(e.target.files[0]));
    setImg(e.target.files[0]);
  }
  function fetchStudents() {
    setLoading(true);
    axios
      .get("/api/student/studentDataByToken", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        setName(res.data.data.name);
        setFatherName(res.data.data.father_name);
        setMotherName(res.data.data.mother_name);
        setRollno(res.data.data.rollno);
        setRegno(res.data.data.regno);
        setCourse(res.data.data.course);
        setSem(res.data.data.sem);
        setSession(res.data.data.session);
        setCategory(res.data.data.category);
        setDob(res.data.data.DOB);
        setPhone(res.data.data.phone);
        setAadhar(res.data.data.aadhar);
        setAddress(res.data.data.address);
        setPhoto(res.data.data.photo);
        if (res.data.data.saved == 1) {
          setDisabled(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  const [datePicker, setDatePicker] = useState(false);
  const showDatePicker = () => {
    setDatePicker(true);
  };
  const hideDatePicker = () => {
    setDatePicker(false);
  };
  useEffect(() => {
    fetchStudents();
  }, []);
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    let formData = new FormData();
    formData.append("name", e.target.name.value);
    formData.append("father_name", e.target.father_name.value);
    formData.append("mother_name", e.target.mother_name.value);
    formData.append("rollno", e.target.rollno.value);
    formData.append("course", e.target.course.value);
    formData.append("sem", e.target.sem.value);
    formData.append("session", e.target.session.value);
    formData.append("dob", e.target.dob.value);
    formData.append("regno", e.target.regno.value);
    formData.append("aadhar", e.target.aadhar.value);
    formData.append("category", e.target.category.value);
    formData.append("phone", e.target.phone.value);
    formData.append("address", e.target.address.value);
    formData.append("photo", img);
    if (img === null) {
      setLoading(false);
      alert("Upload Image");
    } else {
      axios
        .post("/api/student/update", formData, {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((res) => {
          setLoading(false);
          alert(res.data.msg);
          if (res.data.msg === "Saved successfully") {
            window.location.reload();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}

      <div className=" AlumniContainer stu-form-container">
        <div className="Alumni">
          <div className="notice-heading">
            <h2 style={{ textAlign: "center" }}>
              Student Det<span className="underline">ails Form</span>
            </h2>
            <br />
          </div>

          <div className="AlumniForm stu-form">
            <form onSubmit={submitHandler} enctype="multipart/form-data">
              <div className="TopRow">
                {disabled === true ? (
                  <img
                    style={{ width: "120px" }}
                    src={"https://api.vbumca.in/" + photo}
                    alt=""
                  />
                ) : (
                  <div className="UploadImg">
                    <input
                      type="file"
                      id="file"
                      name="image"
                      accept="image/*"
                      onChange={imageHandler}
                      disabled={disabled}
                    />
                    <label htmlFor="file" className="filebox">
                      <span>
                        <FiUpload />
                      </span>
                      <span style={{ textAlign: "center" }}>Upload Image</span>
                      <img id="file-img" src={file} />
                    </label>
                  </div>
                )}
                <div className="nameEmail">
                  <div className="input-box">
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                      required
                      disabled={disabled}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <div className="row">
                      <div className="input-box">
                        <input
                          type="text"
                          name="father_name"
                          value={FatherName}
                          onChange={(e) => setFatherName(e.target.value)}
                          placeholder="Father's Name"
                          disabled={disabled}
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="input-box">
                        <input
                          type="text"
                          name="mother_name"
                          value={MotherName}
                          onChange={(e) => setMotherName(e.target.value)}
                          placeholder="Mother's Name"
                          disabled={disabled}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="BottomRow">
                <div className="RowLeft">
                  <div className="row">
                    <div className="input-box">
                      <input
                        type="number"
                        name="rollno"
                        value={rollno}
                        onChange={(e) => setRollno(e.target.value)}
                        placeholder="Univ. Roll No."
                        disabled={disabled}
                        required
                      />
                    </div>
                  </div>
                  <div className="row course-sem-session">
                    <div className="input-box">
                      <select
                        name="course"
                        disabled={disabled}
                        onChange={(e) => {
                          setCourse(e.target.value);
                        }}
                        required
                      >
                        {course === null ? (
                          ""
                        ) : (
                          <option value={course}> {course}</option>
                        )}

                        <option value=""> Course</option>
                        <option value="BCA">BCA</option>
                        <option value="MCA">MCA</option>
                      </select>
                    </div>
                    <div className="input-box">
                      <select name="sem" disabled={disabled} required>
                        {sem === null ? (
                          ""
                        ) : (
                          <option value={sem}> {sem}</option>
                        )}{" "}
                        {course === "BCA"
                          ? dbcourseSem[0].BCA.map((item) => {
                              return <option value={item}>{item}</option>;
                            })
                          : dbcourseSem[0].MCA.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                      </select>
                    </div>
                    <div className="input-box">
                      <select name="session" disabled={disabled} required>
                        {session === null ? (
                          ""
                        ) : (
                          <option value={session}> {session}</option>
                        )}{" "}
                        <option value=""> Session</option>
                        {course === "BCA"
                          ? dbsession[0].BCA.map((item) => {
                              return <option value={item}>{item}</option>;
                            })
                          : dbsession[0].MCA.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-box">
                      {datePicker ? (
                        <input
                          type="date"
                          onBlur={hideDatePicker}
                          name="dob"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          placeholder="D.O.B"
                          disabled={disabled}
                          required
                        />
                      ) : (
                        <input
                          type="text"
                          onFocus={showDatePicker}
                          name="dob"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                          placeholder="D.O.B"
                          disabled={disabled}
                          required
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-box">
                      <input
                        type="text"
                        name="regno"
                        value={regno}
                        onChange={(e) => setRegno(e.target.value)}
                        placeholder="Reg No."
                        disabled={disabled}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="RowRight">
                  <div className="row">
                    <div className="input-box">
                      <input
                        type="text"
                        ppattern="\d{12}"
                        maxLength={12}
                        name="aadhar"
                        value={aadhar}
                        onChange={(e) => setAadhar(e.target.value)}
                        placeholder="Aadhar No."
                        disabled={disabled}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-box">
                      <select name="category" disabled={disabled} required>
                        {category === null ? (
                          ""
                        ) : (
                          <option value={category}> {category}</option>
                        )}

                        <option value="">Category</option>
                        <option value="Gen">Gen</option>
                        <option value="BC-1">BC-1</option>
                        <option value="BC-2">BC-2</option>
                        <option value="SC">SC</option>
                        <option value="ST">ST</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-box">
                      <input
                        type="text"
                        pattern="\d{10}"
                        maxLength={10}
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        disabled={disabled}
                        placeholder="Mobile No."
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-box">
                      <input
                        type="text"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder=" Address"
                        maxLength={80}
                        disabled={disabled}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              {disabled ? (
                ""
              ) : (
                <input
                  className="submit-btn"
                  type="submit"
                  value="Submit"
                  name="submit"
                  disabled={disabled}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentDetailsForm;
