import React from "react";
import Loader from "../Loader";
import { useState } from "react";
import { HiAcademicCap } from "react-icons/hi";
import { BsFillCalendarFill, BsPinFill } from "react-icons/bs";
import axios from "axios";
import { useEffect } from "react";
function RecentNotice() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [noticeList, setNoticeList] = useState([]);
  useEffect(() => {
    fetchNotice();
  }, []);

  const fetchNotice = () => {
    setLoading(true);
    axios
      .post("/api/notices/getNoticeListAdmin", {
        pageno: 1,
      })
      .then((res) => {
        setLoading(false);
        setNoticeList(res.data.results);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const deleteNotice = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/notices/delete",
        { id: id },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        alert(res.data.msg);
        console.log(res.data);
        fetchNotice();
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const PublishNotice = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/notices/publish",
        { id: id },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        fetchNotice();
        alert(res.data.msg);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const unPublishNotice = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/notices/unpublish",
        { id: id },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        fetchNotice();
        alert(res.data.msg);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="RecentNotice-container">
        <div className="Recent-notice-search">
          <div className="Heading">
            <h2>
              Recent <span className="underline">Notices</span>
            </h2>
          </div>
          <div className="search-filter">
            <input
              type="text"
              name="notice"
              placeholder="Search By Title"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            {/* <select name="month">
							<option>January</option>
						</select>
						<select name="year">
							<option>2023</option>
							<option>2022</option>
						</select> */}
          </div>
        </div>
        <div className="Recent-notice">
          <div className="Recent-notice-cards">
            {noticeList === undefined ||
              noticeList
                .filter((notice) => {
                  return search.toLowerCase() === ""
                    ? notice
                    : notice.title.toLowerCase().includes(search);
                })
                ?.map((notice, index) => {
                  return (
                    <>
                      <div className="notice-card" key={index}>
                        <div className="card-title">
                          <h3>
                            <BsPinFill className="icon" color="#0038A3" />
                            {notice.title}
                          </h3>
                        </div>
                        <div className="card-desc">
                          <div className="topic">
                            <span>
                              <HiAcademicCap className="icon" size={"22px"} />
                            </span>
                            <span>{notice.subject}</span>
                          </div>
                          <span>|</span>
                          <div className="date">
                            <span>
                              <BsFillCalendarFill className="icon" />
                            </span>
                            <span>
                              {new Date(notice.created_at).toDateString()}
                            </span>
                          </div>
                        </div>
                        <div className="card-action">
                          {/* <button className="edit-btn">Edit </button> */}
                          <button
                            className="delete-btn"
                            onClick={() => {
                              deleteNotice(notice.id);
                            }}
                          >
                            Delete
                          </button>

                          {notice.published == 0 ? (
                            <button
                              className="un-publish-btn"
                              onClick={() => {
                                PublishNotice(notice.id);
                              }}
                            >
                              Publish
                            </button>
                          ) : (
                            <button
                              className="un-publish-btn"
                              onClick={() => {
                                unPublishNotice(notice.id);
                              }}
                            >
                              Unpublish
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentNotice;
