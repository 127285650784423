import React, { useState } from "react";
import Loader from "../Components/Loader";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import Calander from "../Components/Dashboard/Calander";
import Stats from "../Components/Dashboard/Stats";
import StudentDetails from "../Components/Dashboard/StudentDetails";
import NoticeSection from "../Components/Dashboard/NoticeSection";
import Complain from "../Components/Dashboard/Complain";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

function Home() {
	// const [cookies, setCookie] = useCookies(["user"]);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	useEffect(() => {
		checkLogin();
		fetchData();		
	},[]);

	const checkLogin = () => {
		// if (cookies.auth_token === "undefined" || cookies.admin === "undefined") {
		// 	navigate("/login");
	
		// }
		if (localStorage.getItem("type") !== "admin") {
			navigate("/login");
		} else if (!localStorage.getItem("auth_token")) {
			navigate("/login");
		}
	};
	function fetchData() {
		setLoading(true);
		axios
			.get("/api/admin/homepageData", {
				// headers: {
				// 	"X-Authorization": "Bearer " + cookies.auth_token,
				// },
				headers:{
					"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
				}
			})
			.then((res) => {
				setLoading(false);
				setData(res.data);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}

	return (
		<>
			{loading ? <Loader /> : ""}
			<SideBar />
			<TopBar />
			<div className="container">
				<div className="Heading">
					<h2>
						<span className="underline">Dashbo</span>ard
					</h2>
				</div>

				{/* Stats  */}
				<Stats
					alumni={data.aluminiRequestCount}
					fee={data.feeRequestCount}
					student={data.studentCount}
				/>

				<div id="dashboard">
					<section className="dashboard-left">
						<aside className="dashboard-leftTop">
							<StudentDetails data={data.studentList} />
						</aside>
						<aside className="dashboard-leftBottom">
							<NoticeSection data={data.noticeList} />
						</aside>
					</section>
					<section className="dashboard-right">
						<aside className="dashboard-rightTop">
							<Complain data={data.complaintList} />
						</aside>
						<aside className="dashboard-rightBottom">
							<Calander />
						</aside>
					</section>
				</div>
			</div>
		</>
	);
}

export default Home;
