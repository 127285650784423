import React, { useState } from "react";
import axios from "axios";
import Loader from "../Loader";
function CreateAccount() {
	const [loading, setLoading] = useState(false);
	const accountCreateHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		axios
			.post(
				"/api/student/createAccount",
				{
					name: e.target.name.value,
					email: e.target.email.value,
					classRoll: e.target.classRoll.value,
					password: e.target.password.value,
				},
				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoading(false);
				alert(res.data.msg);
				// console.log(res.data.msg);
				if (res.data.msg === "Student added") {
					window.location.reload();
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="account">
				<form onSubmit={accountCreateHandler} method="post">
					<div className="input-box">
						<input type="text" name="name" placeholder="Enter Name" required />
					</div>
					<div className="input-box">
						<input
							type="email"
							name="email"
							placeholder="Enter Email"
							required
						/>
					</div>
					<div className="input-box">
						<input
							type="number"
							name="classRoll"
							placeholder="Enter Class Roll"
							required
						/>
					</div>
					<div className="input-box">
						<input
							type="password"
							name="password"
							placeholder="Create Password"
							required
						/>
					</div>
					<input
						className="submit-btn"
						type="submit"
						name="submit"
						value="Create"
					/>
				</form>
			</div>
		</>
	);
}

export default CreateAccount;
