import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../Loader";
function UploadNotice() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const checkLogin = () => {
			if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	const [pdf, setPdf] = useState("");

	const handleChange = (e) => {
		// console.log(e.target.files[0]);
		setPdf(e.target.files[0]);
	};

	const noticeHandler = (e) => {
		e.preventDefault();
		setLoading(true);
		let formData = new FormData();
		formData.append("pdf", pdf);
		formData.append("title", e.target.title.value);
		formData.append("subject", e.target.subject.value);
		axios
			.post(
				"/api/notices/add",
				formData,

				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoading(false);
				alert(res.data.msg);
				if (res.data.msg === "Notice Added Successfully") {
					window.location.reload();
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="notice-container">
				<div className="notice-heading">
					<h2>
						<span className="underline">Noti</span>ce
					</h2>
				</div>
				<br />
				<div className="notice-form">
					<form
						onSubmit={noticeHandler}
						accept=".pdf"
						method="post"
						enctype="multipart/form-data"
					>
						<h3>
							Notice Fo<span className="underline">rmat</span>
						</h3>
						<div className="form">
							<div className="form-left">
								<div className="upload-file">
									<input
										type="file"
										name="pdf"
										onChange={handleChange}
										required
									/>
								</div>
							</div>
							<div className="form-right">
								<div className="input-box">
									<input
										type="text"
										name="title"
										placeholder="Title"
										required
									/>
								</div>
								<div className="input-box">
									<select name="subject" required>
										<option value="select">
											Select Topic About the Notice
										</option>
										<option value="admission">Admission</option>
										<option value="result">Result</option>
									</select>
								</div>
							</div>
						</div>
						<button className="submit-btn">PUBLISH</button>
					</form>
				</div>
			</div>
		</>
	);
}

export default UploadNotice;
