import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

function ComplainList() {
  const [loading, setLoading] = useState(false);
  const [complainList, setComplainList] = useState([]);
  function fetchComplain() {
    setLoading(true);
    axios
      .get("/api/student/getComplaintListAdmin", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.msg !== "Data Not Found!") {
          setComplainList(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  const actionHandler = (e, id) => {
    setLoading(true);
    axios
      .post(
        "/api/student/updateComplaintStatus",
        {
          id: id,
          status: e.target.value,
        },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        alert(res.data.msg);
        fetchComplain();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const deleteHandler = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/student/deleteComplaint",
        {
          id: id,
        },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        fetchComplain();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    fetchComplain();
  }, []);
  /*Pagination*/
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);
  const itemsPerPage = 15;
  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(complainList.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(complainList.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, complainList]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % complainList.length;
    setItemOffSet(newOffset);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="container">
        {/* Alumni Header  */}
        <div className="Recent-notice-search">
          <div className="Heading">
            <h2>
              <span className="underline"> Complaint</span>s List
            </h2>
          </div>
          {/* <div className="search-filter">
						<input type="search" name="search" placeholder="Search Here" />
						<select name="status">
							<option value="">Action</option>
							<option value="Inreview">In Review</option>
							<option value="Pending">Pending</option>
							<option value="Approved">Approved</option>
						</select>
					</div> */}
        </div>
        {/* Students List  */}
        <div className="AlumniListTable">
          <table>
            <thead>
              <tr className="Table-Header">
                <th>S.No</th>
                <th>Date</th>
                <th>Name</th>
                <th>Course</th>
                <th>Sem</th>
                <th>Title</th>
                <th>Description</th>
                <th style={{ width: "100px" }}>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentItems === undefined ||
                currentItems?.map((item, index) => {
                  console.log(item);
                  return (
                    <tr key={index} className="row-link">
                      <td>{index + 1}</td>
                      <td>{new Date(item.created_at).toLocaleDateString()}</td>
                      <td>
                        <Link to={`/studentdetail/` + item.student.id}>
                          {item.student.name}
                        </Link>
                      </td>
                      <td>{item.student.course}</td>
                      <td>{item.student.sem}</td>
                      <td>{item.title}</td>
                      <td className="msg">{item.description}</td>
                      <td>
                        <select
                          name="action"
                          onChange={(e) => {
                            actionHandler(e, item.id);
                          }}
                        >
                          <option value={item.status}>{item.status}</option>
                          <option value="Inreview">In Review</option>
                          <option value="Pending">Pending</option>
                          <option value="Approved">Approved</option>
                        </select>
                      </td>
                      <td>
                        <button
                          className="delete-btn "
                          onClick={() => {
                            deleteHandler(item.id);
                          }}
                        >
                          <RiDeleteBin5Fill />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {/* Pagination  */}

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default ComplainList;
