import React from "react";
import { useState } from "react";
import Loader from "../../../Components/Loader";
import axios from "axios";
import { useEffect } from "react";
function ComplainList() {
	const [loading, setLoading] = useState(false);
	const [complainList, setComplainList] = useState([]);

	useEffect(() => {
		fetchComplain();
	}, []);

	const fetchComplain = () => {
		setLoading(true);
		axios
			.get("/api/student/getComplaintListStudent", {
				headers: {
					"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
				},
			})
			.then((res) => {
				setLoading(false);
				setComplainList(res.data.data);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="RecentNotice-container">
				<div className="Recent-notice-search">
					<div className="Heading">
						<h2>
							Previou<span className="underline">s Complaints</span>
						</h2>
					</div>

					{/* <div className="search-filter">
						<select name="notice">
							<option>Search Complaints</option>
						</select>
						<select name="month">
							<option>January</option>
						</select>
						<select name="year">
							<option>2023</option>
							<option>2022</option>
						</select>
					</div> */}
				</div>
				<div className="Recent-notice">
					<div className="Recent-notice-cards">
						{complainList === undefined ||
							complainList.map((item, index) => {
								return (
									<div className="notice-card" style={{gap:"10px"}} key={index}>
										<div className="title" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
											<h3>
												<span>#{item.id}</span> <span>|&nbsp;</span>
												<span>{item.title}</span>
											</h3>
											<p>{new Date(item.created_at).toLocaleDateString()}</p>
										</div>
										<div className="card-desc">
											<p>{item.description}</p>
										</div>
										<div className={item.status}>{item.status}</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
}

export default ComplainList;
