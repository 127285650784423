import React from "react";
import { FaUserAlt, FaEye, FaRegEyeSlash } from "react-icons/fa";
import { BiSolidLock } from "react-icons/bi";
import Loader from "../Components/Loader";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import { useCookies } from "react-cookie";

function Login() {
	const [name, setName] = useState("");
	// const [cookies, setCookie] = useCookies(["user"]);

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const [passwordType, setPasswordType] = useState("password");
	const [passwordInput, setPasswordInput] = useState("");
	const passwordHandler = (event) => {
		setPasswordInput(event.target.value);
	};
	const toggleEye = () => {
		if (passwordType === "password") {
			setPasswordType("text");
		} else if (passwordType === "text") {
			setPasswordType("password");
		}
	};

	const submitHandler = (e) => {
		setLoading(true);
		e.preventDefault();
		axios
			.post("/api/college/login", {
				username: e.target.username.value,
				password: e.target.password.value,
			})
			.then((res) => {
				setLoading(false);

				alert(res.data.msg);
				if (res.data.msg === "Admin login Successfull") {
					// setCookie("admin", name, { path: "/", expires: 0 });
					// setCookie("Password", passwordInput, { path: "/", expires: 0 });
					// setCookie("auth_token", res.data.token, { path: "/", expires: 0 });
					localStorage.setItem("auth_token", res.data.token);
					localStorage.setItem("type", "admin");
					navigate("/");
				} else if (res.data.msg === "Student Login Successfull") {
					// setCookie("student", name, { path: "/" });
					// setCookie("Password", passwordInput, { path: "/" });
					// setCookie("auth_token", res.data.token, { path: "/" });

					localStorage.setItem("auth_token", res.data.token);
					localStorage.setItem("type", "student");
					navigate("/student/home");
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	const checkLogin = () => {
		if (localStorage.getItem("type") === "student") {
			navigate("/student/home");
		} else if (localStorage.getItem("type")=== "admin") {
			navigate("/");
		}else{
			navigate("/login")
		}
	};
	useEffect(() => {
		checkLogin();
	},[]);

	return (
		<>
			{loading ? <Loader /> : ""}
			<section id="login-container">
				<div className="login-left">
					<div className="heading">
						<h2>
							Welcome to MCA Dept. <br /> <b>Login Panel</b>
						</h2>
					</div>
					<div className="logo">
						<img src="/Images/logo.png" alt="logo" />
						<h2>University Department of Computer Application</h2>
					</div>
				</div>
				<div className="login-right">
					<form  onSubmit={submitHandler} method="POST">
						<div className="login-heading">
							<h2>
								<span className="underline">SIGN</span> IN
							</h2>
						</div>
						<div className="login-form">
							<div className="input-box">
								<FaUserAlt size={"20px"} />
								<input
									type="text"
									name="username"
									placeholder="User ID"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<br />
							<br />
							<div className="input-box">
								<BiSolidLock size={"25px"} />
								<input
									type={passwordType}
									name="password"
									value={passwordInput}
									placeholder="Password"
									onChange={passwordHandler}
								/>
								<span onClick={toggleEye}>
									{passwordType === "text" ? (
										<FaEye size={"20px"} />
									) : (
										<FaRegEyeSlash size={"20px"} />
									)}
								</span>
							</div>
							<div className="forgot">
								<span style={{ display: "flex" }}>
									<input type="checkbox" name="remember" />
									<p>&nbsp;Remember Me</p>
								</span>
								<span>
									<i>Forgot Password ?</i>
								</span>
							</div>
						</div>
						<div className="login-btn">
							<button>Login</button>
						</div>
					</form>
				</div>
			</section>
		</>
	);
}

export default Login;
