import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";

function NoticeSection(props) {
  const notices = props.data;
  return (
    <>
      <div className="Recent-notice-search">
        <div className="Heading">
          <h2>
            Notic<span className="underline">e Section</span>
          </h2>
        </div>
      </div>
      <div className="notice-section">
        <ul>
          {notices === undefined ||
            notices?.map((item, index) => {
              return (
                <li key={index}>
                  <div className="item">
                    <tr className="notice">
                      <td className="notes-icon">
                        <FaRegFileAlt className="icon" />
                      </td>
                      <td>
                        <span>{item.title}</span>
                        <br />
                        <p>{new Date(item.created_at).toDateString()}</p>
                      </td>
                    </tr>
                  </div>
                </li>
              );
            })}
        </ul>
        <Link to="/notice">
          <button>
            View More <BsArrowRight />
          </button>
        </Link>
      </div>
    </>
  );
}

export default NoticeSection;
