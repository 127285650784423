import React from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "./Loader";
import { Link } from "react-router-dom";

function ResearchList() {
  const [researchLists, setresearchLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchResearch();
  }, []);

  const fetchResearch = () => {
    setLoading(true);
    axios
      .get("/api/research/listResearchAdmin", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data.Data);
        if (res.data.msg !== "No data found") {
          setresearchLists(res.data.Data);
        } else {
          setresearchLists([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const approveAlumni = (id) => {
    setLoading(true);
    axios
      .post(
        "/api/research/approve",
        { id: id },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        alert(res.data.msg);
        fetchResearch();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deleteResearch = (id) => {
    setLoading(true);
    axios
      .get("/api/research/delete/" + id, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoading(false);
        alert(res.data.msg);
        fetchResearch();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  /*Pagination*/
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);
  const itemsPerPage = 10;
  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(researchLists.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(researchLists.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, researchLists]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % researchLists.length;
    setItemOffSet(newOffset);
  };
  return (
    <>
      {loading ? <Loader /> : ""}

      <div className="container">
        {/* Alumni Header  */}
        <div className="Recent-notice-search">
          <div className="Heading">
            <h2>
              <span className="underline">Research</span>er Lists
            </h2>
          </div>

          <div className="search-filter">
            {/* <select name="alumni">
							<option>Search Alumni</option>
						</select> */}
            {/* <select
							name="course"
							onChange={(e) => {
								setSearch(e.target.value);
							}}
						>
							<option value="">All Here</option>
							<option value="BCA">BCA</option>
							<option value="MCA">MCA</option>
						</select> */}
            {/* <select name="year">
							<option>2023</option>
							<option>2022</option>
						</select> */}
          </div>
        </div>

        {/* Alumni List  */}
        <div className="AlumniListTable">
          <table>
            <thead>
              <tr className="Table-Header">
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Contact</th>
                <th>Topic</th>
                <th>Guide</th>
                <th>Co Guide</th>
                <th>Faculty</th>
                <th>Pre_Reg._Seminar</th>
                <th>Passing_Year</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems === undefined ||
                currentItems
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : item.course === null
                      ? " "
                      : item.course
                          .toLowerCase()
                          .includes(search.toLowerCase());
                  })
                  ?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td key={index + 1}>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          {/* <td>{new Date(item.dob).toLocaleDateString()}</td> */}
                          <td>{item.rTopic}</td>
                          <td>{item.guide}</td>
                          <td>{item.coGuide}</td>
                          <td>{item.faculty}</td>
                          <td>{item.preRegSeminarDate}</td>
                          <td>{item.passingYear}</td>
                          <td className="msg">{item.address}</td>

                          <td className="action">
                            <button
                              className="delete-btn"
                              onClick={() => {
                                deleteResearch(item.id);
                              }}
                            >
                              Delete
                            </button>

                            {item.approved == 0 ? (
                              <button
                                className="un-publish-btn"
                                onClick={() => {
                                  approveAlumni(item.id);
                                }}
                              >
                                Approve
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </>
                    );
                  })}
            </tbody>
          </table>
        </div>

        {/* Pagination  */}

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default ResearchList;
