import React from 'react'
import {ScaleLoader} from "react-spinners"

function Loader() {
  return (
   <>
      <section id="loader">
       <ScaleLoader color="#183497" />
       </section>

   </>
  )
}

export default Loader