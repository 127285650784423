import React, { useEffect } from "react";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import { useNavigate } from "react-router-dom";

function StudentResult() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	return (
		<>
			<SideBar />
			<TopBar />
			<div className="container">
				{/* Alumni Header  */}
				<div className="Recent-notice-search">
					<div className="Heading">
						<h2>
							<span className="underline"> Students </span> Result
						</h2>
					</div>

					<div className="search-filter">
						<select name="alumni">
							<option>Search Students</option>
						</select>
						<select name="course">
							<option>Course</option>
							<option>BCA</option>
							<option>MCA</option>
						</select>
						<select name="sem">
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
						</select>
					</div>
				</div>

				{/* Alumni List  */}
				<div className="AlumniListTable">
					<table>
						<thead>
							<tr className="Table-Header">
								<th>S.No</th>
								<th>Profile</th>
								<th>Name</th>
								<th>Roll No.</th>
								<th>Contact No.</th>
								<th>Course</th>
								<th>Session</th>
								<th>Result</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>01</td>
								<td>
									<img src="/Images/logo.png" alt="profile" />
								</td>
								<td>Rajiv Ranjan</td>
								<td>20221458787</td>
								<td>9693482587</td>
								<td>BCA</td>
								<td>2021-24</td>
								<td className="res-status">Pass</td>
							</tr>
							<tr>
								<td>01</td>
								<td>
									<img src="/Images/logo.png" alt="profile" />
								</td>
								<td>Rajiv Ranjan</td>
								<td>20221458787</td>
								<td>9693482587</td>
								<td>BCA</td>
								<td>2021-24</td>
								<td className="res-status">Pass</td>
							</tr>
							<tr>
								<td>01</td>
								<td>
									<img src="/Images/logo.png" alt="profile" />
								</td>
								<td>Rajiv Ranjan</td>
								<td>20221458787</td>
								<td>9693482587</td>
								<td>BCA</td>
								<td>2021-24</td>
								<td className="res-status">Pass</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
}

export default StudentResult;
