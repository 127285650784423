import React, { useEffect } from "react";
import TopBar from "../Components/TopBar";
import SideBar from "../Components/SideBar";
import { useNavigate } from "react-router-dom";
import ResearchList from "../Components/ResearchList";

function Research() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	return (
		<>
			<SideBar />
			<TopBar />
			<ResearchList/>
		</>
	);
}

export default Research;
