import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import CreateAccount from "./CreateAccount";
import axios from "axios";
import Loader from "../Loader";
import UpdateSem from "./UpdateSem";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import db from "../../db.json";
import { TbDatabaseExport } from "react-icons/tb";

function StudentList() {
  const [search, setSearch] = useState("");
  const [course, setCourse] = useState("BCA");
  const [searchByName, setSearchByName] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const dbcourseSem = db.courseSem;
  // const BCA = course.filter((item) => {
  // 	return item.BCA;
  // });
  // console.log(course.MCA);
  const openCreateAccountModal = () => {
    setOpenCreateModal(true);
  };
  const closeCreateAccountModal = () => {
    setOpenCreateModal(false);
  };
  const openUpdateSemModal = () => {
    setOpenUpdateModal(true);
  };
  const closeUpdateSemModal = () => {
    setOpenUpdateModal(false);
  };
  useEffect(() => {
    fetchStudents();
  }, []);

  function fetchStudents() {
    setLoading(true);
    axios
      .get("/api/student/getStudentList", {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        if (res.data.msg !== "No Data Found") {
          setData(res.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  const newData = data?.map((item, index) => {
    return {
      S_No: index + 1,
      Name: item?.name,
      Phone: item?.phone,
      Class_Roll: item?.classRoll,
      Univ_Roll: item?.rollno,
      Course: item?.course,
      Semester: item?.sem,
      Session: item?.session,
    };
  });
  function editStudent(sid) {
    setLoading(true);
    axios
      .post(
        "/api/student/allowEditStudentDetails",
        {
          id: sid,
        },
        {
          headers: {
            "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
          },
        }
      )
      .then((res) => {
        setLoading(false);
        fetchStudents();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  /*Pagination*/
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffSet] = useState(0);
  const itemsPerPage = 20;
  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(data.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, data]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffSet(newOffset);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="container">
        {/* Alumni Header  */}
        <div className="Recent-notice-search">
          <div className="Heading">
            <h2>
              <span className="underline"> Student</span>s List
            </h2>
          </div>

          <div className="search-filter">
            <input
              type="text"
              name="student"
              placeholder="Search "
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchByName(true);
              }}
            />
            <select
              name="course"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchByName(false);
                setCourse(e.target.value);
              }}
            >
              <option value="BCA">BCA</option>
              <option value="MCA">MCA</option>
            </select>
            <select name="sem">
              {course === "BCA"
                ? dbcourseSem[0].BCA.map((item) => {
                    return <option value={item}>{item}</option>;
                  })
                : dbcourseSem[0].MCA.map((item) => {
                    return <option value={item}>{item}</option>;
                  })}
            </select>
            <button className=" submit-btn" onClick={openUpdateSemModal}>
              Update Sem
            </button>
            <button className=" submit-btn" onClick={openCreateAccountModal}>
              Create Ac.
            </button>
            <CSVLink data={newData} filename={"StudentList.csv"}>
              <button
                style={{ padding: "5px", marginTop: "5px" }}
                className="submit-btn"
              >
                <TbDatabaseExport />
              </button>
            </CSVLink>
          </div>
        </div>

        {/* Students List  */}
        <div className="AlumniListTable">
          <table>
            <thead>
              <tr className="Table-Header">
                <th>S.No</th>
                <th>Profile</th>
                <th>Name</th>
                <th>Class Roll</th>
                <th>Roll No.</th>
                <th>Contact No.</th>
                <th>Course</th>
                <th>Sem</th>
                <th>Session</th>
                <th>Access</th>
              </tr>
            </thead>
            <tbody>
              {currentItems === undefined ||
                currentItems
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : searchByName
                      ? JSON.stringify(item)
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      : item.course === null
                      ? " "
                      : item.course
                          .toLowerCase()
                          .includes(search.toLowerCase());
                  })
                  ?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.photo === null ? (
                              <img src="/Images/avtar.webp" alt="profile" />
                            ) : (
                              <img
                                src={"https://api.vbumca.in/" + item.photo}
                                alt="profile"
                              />
                            )}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.name}
                          </Link>{" "}
                        </td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.classRoll === null ? "-" : item.classRoll}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.rollno === null ? "-" : item.rollno}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.phone === null ? "-" : item.phone}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.course === null ? "-" : item.course}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.sem === null ? "-" : item.sem}
                          </Link>
                        </td>
                        <td>
                          <Link to={`/studentdetail/` + item.id}>
                            {item.session === null ? "-" : item.session}
                          </Link>
                        </td>
                        <td className="res-status">
                          {item.saved == 1 ? (
                            <button
                              onClick={() => {
                                editStudent(item.id);
                              }}
                            >
                              Allow Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>

        {/* Pagination  */}

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
      {openCreateModal ? (
        <Modal open={openCreateModal} onClose={closeCreateAccountModal} center>
          <CreateAccount />
        </Modal>
      ) : (
        <Modal open={openUpdateModal} onClose={closeUpdateSemModal} center>
          <UpdateSem />
        </Modal>
      )}
    </>
  );
}

export default StudentList;
