import React, { useState } from "react";
import { GoAlertFill } from "react-icons/go";
import { PiNotepadBold } from "react-icons/pi";
import { BsCurrencyDollar } from "react-icons/bs";
import { RiLogoutCircleLine } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../Components/Loader";
// import { useCookies } from "react-cookie";
import { Modal } from "react-responsive-modal";
import Logout from "../../Components/Logout";

function Home() {
	// const [cookies, setCookie] = useCookies(["user"]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const openLogoutModal = () => {
		setOpenModal(true);
	};
	const closeLogoutModal = () => {
		setOpenModal(false);
	};

	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "student") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};

		// const checkLogin = () => {
		// 	if (cookies.auth_token === "undefined" || cookies.student === "undefined") {
		// 		navigate("/login");
		// 	}
		// };
		checkLogin();
		fetchStu();
	}, []);
	function fetchStu() {
		setLoading(true);
		axios
			.get("/api/student/studentDataByToken", {
				headers: {
					"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
				},
			})
			.then((res) => {
				setLoading(false);
				if (res.data.data.aadhar === null) {
					navigate("/student/details");
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}

	return (
		<>
			{loading ? <Loader /> : ""}
			<section id="login-container" classname="action-container">
				<div className="login-left">
					<div className="heading">
						<h2>
							Welcome to MCA Dept. <br /> <b>Student Panel</b>
						</h2>
					</div>
					<div className="logo">
						<img src="/Images/logo.png" alt="logo" />
						<h2>University Department of Computer Application</h2>
					</div>
				</div>
				<div className="action-right">
					<div className="login-heading">
						<h2>
							<span className="underline">Choose a</span>ction
						</h2>
					</div>
					<div className="actions">
						<ul>
							<li
								onClick={() => {
									navigate("/student/complain");
								}}
							>
								<span>
									<GoAlertFill size={"25px"} color="red" />
								</span>
								<span>Complain Box</span>
							</li>

							<li
								onClick={() => {
									navigate("/student/fee");
								}}
							>
								<span>
									<BsCurrencyDollar size={"25px"} />
								</span>
								<span>Fee Submission</span>
							</li>
							<li
								onClick={() => {
									navigate("/student/details");
								}}
							>
								<span>
									<TbEdit size={"25px"} />
								</span>
								<span>Student Details Form</span>
							</li>
							<li
							// onClick={() => {
							// 	navigate("/student/result");
							// }}
							>
								<span>
									<PiNotepadBold size={"25px"} />
								</span>
								<span>Results</span>
							</li>
							<li onClick={openLogoutModal}>
								<span>
									<RiLogoutCircleLine size={"25px"} />
								</span>
								<span>Logout</span>
							</li>
						</ul>
					</div>
				</div>
				<Modal open={openModal} onClose={closeLogoutModal} center>
					<Logout />
				</Modal>
			</section>
		</>
	);
}

export default Home;
