import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import FeePieChart from "./FeePieChart";

function StudentDetail() {
  let { stuId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [showpassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchStu();
    checkLogin();
  }, []);

  function togglePassword() {
    setShowPassword(!showpassword);
  }
  const checkLogin = () => {
    if (localStorage.getItem("type") !== "admin") {
      navigate("/login");
    } else if (!localStorage.getItem("auth_token")) {
      navigate("/login");
    }
  };
  function fetchStu() {
    setLoading(true);
    axios
      .get("api/student/studentData/" + stuId, {
        headers: {
          "X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="Heading">
        <h2>
          <span className="underline">Prof</span>ile
        </h2>
      </div>

      <div className="stu-details-container">
        <div className="top">
          <div className="left">
            <div className="profile">
              <h2>Student Details</h2>
              {data.photo === null ? (
                <img
                  src="/Images/avtar.webp"
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
              ) : (
                <img src={"https://api.vbumca.in/" + data.photo} alt="" />
              )}

              <h4>{data.name}</h4>
              <span>
                {data.course} ({data.session})
              </span>
            </div>
            <div className="profile-desc">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="box">
                  <label>Roll No. </label>
                  <p>{data.rollno}</p>
                </div>
                <div className="box">
                  <label>Clas Roll : {data.classRoll} </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="box">
                  <label>Registration No. </label>
                  <p>{data.regno}</p>
                </div>
                <div className="box">
                  <label>Semester : {data.sem} </label>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="box">
                  <label>Phone No. </label>
                  <p>{data.phone}</p>
                </div>
                <div className="box">
                  <label onClick={togglePassword}>
                    {showpassword ? "Hide Password" : "Show Password"}
                  </label>
                  <p id="password">{showpassword ? data.password : ""}</p>
                </div>
              </div>
              <div className="box">
                <label>Email </label>
                <p>{data.email}</p>
              </div>
            </div>
          </div>
          <div className="right">
            <h2>Personal Information</h2>
            <div className="profile-desc">
              <div className="box">
                <label>Father Name </label>
                <p>{data.father_name}</p>
              </div>

              <div className="box">
                <label>Mother Name </label>
                <p>{data.mother_name}</p>
              </div>
              <div className="box">
                <label>Aadhar No. </label>
                <p>{data.aadhar}</p>
              </div>
              <div className="box">
                <label>Date of Birth </label>
                <p>{data.DOB}</p>
              </div>
              <div className="box">
                <label>Category </label>
                <p>{data.category}</p>
              </div>
              <div className="box">
                <label>Home Address </label>
                <p>{data.address}</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="top">
					<div className="left">
						<h2>Fee Report</h2>
						<FeePieChart />
					</div>
					<div className="right">
						<h2>Marks Obtained</h2>
					</div>
				</div> */}
      </div>
    </>
  );
}

export default StudentDetail;
