import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

function StudentDetails(props) {
  const students = props.data;
  const navigate = useNavigate();

  return (
    <>
      {/* Alumni Header  */}
      <div className="Recent-notice-search">
        <div className="Heading">
          <h2>
            Stude<span className="underline">nt Details</span>
          </h2>
        </div>
      </div>

      {/* Students List  */}
      <div className="AlumniListTable" style={{ marginTop: "10px" }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr className="Table-Header">
              <th>S.No</th>
              <th>Name</th>
              <th>Roll No.</th>
              <th>Course</th>
              <th>Session</th>
            </tr>
          </thead>
          <tbody>
            {students === undefined ||
              students?.map((item, index) => {
                return (
                  <tr
                    className="row-link"
                    key={index}
                    onClick={() => {
                      navigate("/studentdetail/" + item.id);
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.rollno}</td>
                    <td>{item.course}</td>
                    <td>{item.session}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <Link to="/studentList">
          <span className="see-all">
            <p>See All</p> <BsArrowRight />
          </span>
        </Link>
      </div>
    </>
  );
}

export default StudentDetails;
