import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../../Components/Loader";
function FeeTxn() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	useEffect(() => {
		fetchFee();
	}, []);
	function fetchFee() {
		setLoading(true);
		axios
			.get(
				"/api/student/listFeeDetailStudent",

				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoading(false);
				setData(res.data.data);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}

	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="feeTxn">
				<h3>Previous Transaction</h3>
				<br />
				<table>
					<thead>
						<tr>
							<th>S.No</th>
							<th>Date</th>
							<th>UTR.No</th>
							<th>Sem</th>
							<th>Amount</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{data === undefined ||
							data.map((item, index) => {
								return (
									<tr>
										<td key={index + 1}>{index + 1}</td>
										<td style={{ color: "#001AFFDE" }}>
											{new Date(item.created_at).toDateString()}
										</td>
										<td>{item.txnId}</td>
										<td>{item.sem}</td>
										<td className="ruppee">&#8377; {item.amount}</td>
										<td>{item.status}</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</>
	);
}

export default FeeTxn;
