import React from "react";
import Header from "../Components/Header";
import NavBar from "../Components/NavBar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StudentDetailsForm from "../Components/Student/StudentDetailsForm";

function StudentDetails() {
	
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "student") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	},[]);
	return (
		<>
			<Header margin={"0px"} />
			<div className="student-panel">
				<NavBar />
				<StudentDetailsForm />
			</div>
		</>
	);
}

export default StudentDetails;
