import React, { useState } from "react";
import Loader from "../../../Components/Loader";
import axios from "axios";

function ComplainForm() {
	const [loading, setLoading] = useState(false);

	const registerComplaint = (e) => {
		e.preventDefault();
		setLoading(true);
		axios
			.post(
				"/api/student/registerComplaint",
				{
					title: e.target.title.value,
					description: e.target.description.value,
				},
				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoading(false);
				console.log(res);
				alert(res.data.msg);
				if(res.data.msg === "Submitted Successfully!"){
					window.location.reload();
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="notice-container">
				<div className="notice-heading">
					<h2>
						<span className="underline">Complai</span>n Form
					</h2>
				</div>
				<br />
				<div className="notice-form complain-form">
					<form method="post" onSubmit={registerComplaint}>
						<div className="form">
							<div className="form-right">
								<label>Title</label>
								<div className="input-box">
									<input type="text" name="title" required maxLength={30}/>
								</div>
								<br />
								<label>Description</label>
								<div className="input-box">
									<textarea
										name="description"
										placeholder="Describe Your Complain Title"
										rows={"7"}
										maxLength={150}
										required
									></textarea>
								</div>
							</div>
						</div>
						<button className="submit-btn">POST</button>
					</form>
					<img src="/Images/background-1.svg" alt="img" />
				</div>
			</div>
		</>
	);
}

export default ComplainForm;
