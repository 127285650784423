import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import ComplainForm from "../Components/Complain/ComplainForm";
import ComplainList from "../Components/Complain/ComplainList";
import NavBar from "../Components/NavBar";
import axios from "axios";
import Loader from "../../Components/Loader";

function Complain() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "student") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
		fetchStu();
	},[]);
	function fetchStu() {
		setLoading(true);
		axios
			.get("/api/student/studentDataByToken", {
				headers: {
					"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
				},
			})
			.then((res) => {
				setLoading(false);
				if (res.data.data.aadhar === null) {
					navigate("/student/details");
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}
	return (
		<>
			{loading ? <Loader /> : ""}
			<Header margin={"0px"} />
			<div className="student-panel">
				<NavBar />
				<ComplainForm />
				<ComplainList />
			</div>
		</>
	);
}

export default Complain;
