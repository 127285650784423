import React, { useEffect } from "react";
import SideBar from "../Components/SideBar";
import TopBar from "../Components/TopBar";
import FeeList from "../Components/Fee Collection/FeeList";
import { useNavigate } from "react-router-dom";

function Fee() {
	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	return (
		<>
			<SideBar />
			<TopBar />
			<FeeList />
		</>
	);
}

export default Fee;
