import axios from "axios";
import React, { useState } from "react";
import Loader from "../Loader";

function AddResources() {
	const [loading, setLoding] = useState(false);
	const addResources = (e) => {
		e.preventDefault();
		setLoding(true);
		axios
			.post(
				"/api/resource/add",
				{
					title: e.target.title.value,
					desc: e.target.description.value,
					link: e.target.link.value,
					designation: e.target.designation.value,
					phone: e.target.Phone.value,
					email: e.target.email.value,
				},
				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoding(false);
				alert(res.data.msg);
				if (res.data.msg === "Saved Successfully!") {
					window.location.reload();
				}
			})
			.catch((err) => {
				setLoding(false);
				console.log(err);
			});
	};
	return (
		<>
			{loading ? <Loader /> : ""}
			<div className="notice-container">
				<div className="notice-heading">
					<h2>
						<span className="underline">Add Res</span>ources
					</h2>
				</div>
				<br />
				<div className="notice-form complain-form">
					<form method="post" onSubmit={addResources}>
						<div className="form">
							<div className="resources-form">
								<div className="form-right">
									<label>Title*</label>
									<div className="input-box">
										<input type="text" name="title" required />
									</div>

									<label>Description</label>
									<div className="input-box">
										<textarea
											name="description"
											placeholder="Describe Your  Title"
											rows={"5"}
										></textarea>
									</div>
									<label htmlFor="link">Link</label>
									<div className="input-box">
										<input type="url" name="link" />
									</div>
								</div>
								<div className="form-right">
									<label htmlFor="designation">Designation</label>
									<div className="input-box">
										<input type="text" name="designation" />
									</div>
									<label htmlFor="Phone">Phone</label>
									<div className="input-box">
										<input
											type="text"
											pattern="\d*"
											name="Phone"
											maxLength={10}
										/>
									</div>
									<label htmlFor="Email">Email</label>
									<div className="input-box">
										<input type="email" name="email" />
									</div>
								</div>
							</div>
						</div>
						<input type="submit" className="submit-btn" value="ADD" />
					</form>
					{/* <img src="/Images/background-1.svg" alt="img" /> */}
				</div>
			</div>
		</>
	);
}

export default AddResources;
