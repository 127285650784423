import React, { useEffect, useState } from "react";
import TopBar from "../Components/TopBar";
import SideBar from "../Components/SideBar";
import axios from "axios";
import Loader from "../Components/Loader";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

function Enquiry() {
	const [search, setSearch] = useState("");
	const [searchByEmail, setSearchByEmail] = useState(false);

	const navigate = useNavigate();
	useEffect(() => {
		const checkLogin = () => {
			if (localStorage.getItem("type") !== "admin") {
				navigate("/login");
			} else if (!localStorage.getItem("auth_token")) {
				navigate("/login");
			}
		};
		checkLogin();
	});
	const [loading, setLoading] = useState(false);
	const [enquiryList, setEnquiryList] = useState([]);

	useEffect(() => {
		fetchEnquiry();
	}, [setSearch]);

	const fetchEnquiry = () => {
		setLoading(true);
		axios
			.get("/api/enquiry/getAll", {
				headers: {
					"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
				},
			})
			.then((res) => {
				setLoading(false);
				if (res.data.msg !== "No data Available") {
					setEnquiryList(res.data.data);
				}else{
					setEnquiryList([])
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	//Delete Enquiry
	const deleteEnquiry = (id) => {
		setLoading(true);
		axios
			.post(
				"/api/enquiry/delete",
				{
					id: id,
				},
				{
					headers: {
						"X-Authorization": "Bearer " + localStorage.getItem("auth_token"),
					},
				}
			)
			.then((res) => {
				setLoading(false);
				fetchEnquiry();
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};
	/*Pagination*/
	const [currentItems, setCurrentItems] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffSet, setItemOffSet] = useState(0);
	const itemsPerPage = 10;
	useEffect(() => {
		const endOffset = itemOffSet + itemsPerPage;
		setCurrentItems(enquiryList.slice(itemOffSet, endOffset));
		setPageCount(Math.ceil(enquiryList.length / itemsPerPage));
	}, [itemOffSet, itemsPerPage, enquiryList]);
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % enquiryList.length;
		setItemOffSet(newOffset);
	};

	return (
		<>
			{loading ? <Loader /> : ""}
			<SideBar />
			<TopBar />

			<div className="container">
				{/* Alumni Header  */}
				<div className="Recent-notice-search">
					<div className="Heading">
						<h2>
							<span className="underline"> Enquir</span>y List
						</h2>
					</div>

					<div className="search-filter">
						<input
							type="text"
							name="name"
							placeholder="Search By Email"
							onChange={(e) => {
								setSearch(e.target.value);
								setSearchByEmail(true);
							}}
						/>
						<select
							name="course"
							onChange={(e) => {
								setSearch(e.target.value);
								setSearchByEmail(false);
							}}
						>
							<option value="">Course</option>
							<option value="BCA">BCA</option>
							<option value="MCA">MCA</option>
						</select>
						{/* <select name="sem">
							<option>1</option>
							<option>2</option>
							<option>3</option>
							<option>4</option>
							<option>5</option>
							<option>6</option>
						</select> */}
					</div>
				</div>

				{/* Enquiry List  */}
				<div className="AlumniListTable">
					<table>
						<thead>
							<tr className="Table-Header">
								<th>S.No</th>
								<th>Name</th>
								<th>Email</th>
								<th>Contact No.</th>
								<th>Course</th>
								<th>Message</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{currentItems === undefined ||
								currentItems
									.filter((item) => {
										return search.toLowerCase() === ""
											? item
											: searchByEmail
											? item.email.toLowerCase().includes(search.toLowerCase())
											: item.course === null
											? " "
											: item.course
													.toLowerCase()
													.includes(search.toLowerCase());
									})
									?.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											<td>{item.name}</td>
											<td>{item.email}</td>
											<td>{item.phone}</td>
											<td>{item.course}</td>
											<td className="msg">{item.description}</td>
											<td>
												<button
													className="delete-btn"
													onClick={() => {
														deleteEnquiry(item.id);
													}}
												>
													Delete
												</button>
											</td>
										</tr>
									))}
						</tbody>
					</table>
				</div>

				{/* Pagination  */}

				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={5}
					pageCount={pageCount}
					previousLabel="<"
					renderOnZeroPageCount={null}
				/>
			</div>
		</>
	);
}

export default Enquiry;
